<template>
  <div class="page">
    <section>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index-expertCommittee' }"
          >专家委员</el-breadcrumb-item
        >
        <el-breadcrumb-item>医生详情</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <img class="backgroud" src="@image/bj.png" />
    <section>
      <div class="doctorDetails">
        <img class="avatar" :src="detail.images" />
        <div class="information">
          <span>{{ detail.name }}</span>
          <span class="position">{{ detail.position }}</span>
        </div>
        <div class="Physician">
          <span class="director">{{ detail.station }}</span>
          <span>{{ detail.department }}</span>
        </div>
        <span class="address">{{ detail.hospital }}</span>
        <span class="subTitle" v-html="detail.content"></span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: "",
    };
  },
  async created() {
    this.xin.isLoadding(true);
    await this.getExpert_info(this.$route.query.id);
    this.xin.isLoadding(false);
  },
  methods: {
    async getExpert_info(id) {
      let res = await this.$newsApi.getExpert_info({ expert_id: id });
      if (res.code == 200) {
        this.detail = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  ::v-deep span {
    background-color: transparent !important;
  }
  background: #f2f2f2;
  > .background {
    height: 200px;
  }
  > section {
    display: flex;
    flex-direction: column;
    > .doctorDetails {
      display: flex;
      flex-direction: column;
      align-items: center;
      > .avatar {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 50%;
        margin-top: -100px;
      }
      > .information {
        display: flex;
        flex-direction: row;
        font-size: 24px;
        color: #333333;
        margin: 10px 0;
        > .position {
          font-size: 12px;
          color: $--themeColor;
          width: 80px;
          height: 25px;
          border: 1px solid $--themeColor;
          border-radius: 6px;
          text-align: center;
          line-height: 25px;
          margin-left: 20px;
        }
      }
      > .Physician {
        display: flex;
        flex-direction: row;
        color: #333333;
        font-size: 16px;
        margin-bottom: 10px;
        > .director {
          margin-right: 10px;
        }
      }
      > .address {
        font-size: 16px;
        margin-bottom: 40px;
      }
      > .subTitle {
        font-size: 14px;
        color: #333333;
        line-height: 1.8;
        text-indent: 50px;
      }
    }
  }
}
</style>
